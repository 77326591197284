<template>
    <div class="c-toggle-buttons" v-if="button">
        <div class="c-toggle-buttons__container" :class="{ [`is-button-${button}`]: button }">
            <label class="c-toggle-buttons__button-container"  @click="toggleButton('ownership')">
                <span 
                    class="c-toggle-buttons__button"
                    :class="{ 'is-active': button == 'left' }">
                    Buy a holiday home
                </span>
            </label>

            <label class="c-toggle-buttons__button-container" @click="toggleButton('holiday')">
                <span 
                    class="c-toggle-buttons__button" 
                    :class="{ 'is-active': button == 'right' }">
                    Book a holiday
                </span>
            </label>
        </div>
    </div>
</template>

<script setup>
const siteStore = useSiteStore();
const button = ref('left');

const toggleButton = (value) => {
  if (value === 'ownership') {
    button.value = 'left';
  }

  if (value === 'holiday') {
    button.value = 'right';
  }

  siteStore.setHolidayFilterState(value);

};

onMounted(() => {
  if (siteStore.state.holidayFilterState === 'ownership') {
    button.value = 'left';
  }

  if (siteStore.state.holidayFilterState === 'holiday') {
    button.value = 'right';
  }
});
</script>
